<template>
	<Header></Header>
	<div class="Material" style="min-height:900px;">
		<div class="MaterialBox">
			<div class="content" v-if="type == 1">
				<div class="title">
					区块链
				</div>
				<div class="link" @click="goToUrl">
					出自：百度百科
				</div>
				<div style="margin:15px 0px;">
<!--					<img style="width:100%;" src="../../../assets/img/qkl.jpeg">-->
					<img style="width:100%;" src="/img/qkl.jpeg">
				</div>
				<div class="ii">
					区块链，就是一个又一个区块组成的链条。每一个区块中保存了一定的信息，它们按照各自产生的时间顺序连接成链条。这个链条被保存在所有的服务器中，只要整个系统中有一台服务器可以工作，整条区块链就是安全的。这些服务器在区块链系统中被称为节点，它们为整个区块链系统提供存储空间和算力支持。如果要修改区块链中的信息，必须征得半数以上节点的同意并修改所有节点中的信息，而这些节点通常掌握在不同的主体手中，因此篡改区块链中的信息是一件极其困难的事。相比于传统的网络，区块链具有两大核心特点：一是数据难以篡改、二是去中心化。基于这两个特点，区块链所记录的信息更加真实可靠，可以帮助解决人们互不信任的问题。
				</div>
				<div class="name">
					起源
				</div>
				<div class="ii">
					<p>
						区块链起源于比特币，2008年11月1日，一位自称中本聪(Satoshi Nakamoto)的人发表了《比特币:一种点对点的电子现金系统》一文 [4]  ，阐述了基于P2P网络技术、加密技术、时间戳技术、区块链技术等的电子现金系统的构架理念，这标志着比特币的诞生。两个月后理论步入实践，2009年1月3日第一个序号为0的创世区块诞生。几天后2009年1月9日出现序号为1的区块，并与序号为0的创世区块相连接形成了链，标志着区块链的诞生。
					</p>
					<p>
						狭义区块链是按照时间顺序，将数据区块以顺序相连的方式组合成的链式数据结构，并以密码学方式保证的不可篡改和不可伪造的分布式账本。广义区块链技术是利用块链式数据结构验证与存储数据，利用分布式节点共识算法生成和更新数据，利用密码学的方式保证数据传输和访问的安全、利用由自动化脚本代码组成的智能合约，编程和操作数据的全新的分布式基础架构与计算范式。
					</p>
				</div>
				<div class="name">
					发展历程
				</div>
				<div class="ii">
					<p>
						2008年由中本聪第一次提出了区块链的概念，在随后的几年中，区块链成为了电子货币比特币的核心组成部分：作为所有交易的公共账簿。通过利用点对点网络和分布式时间戳服务器，区块链数据库能够进行自主管理。为比特币而发明的区块链使它成为第一个解决重复消费问题的数字货币。比特币的设计已经成为其他应用程序的灵感来源。
					</p>
					<p>
						2014年，"区块链2.0”成为一个关于去中心化区块链数据库的术语。对这个第二代可编程区块链，经济学家们认为它是一种编程语言，可以允许用户写出更精密和智能的协议 。因此，当利润达到一定程度的时候，就能够从完成的货运订单或者共享证书的分红中获得收益。区块链2.0技术跳过了交易和“价值交换中担任金钱和信息仲裁的中介机构”。它们被用来使人们远离全球化经济，使隐私得到保护，使人们“将掌握的信息兑换成货币”，并且有能力保证知识产权的所有者得到收益。第二代区块链技术使存储个人的“永久数字ID和形象”成为可能，并且对“潜在的社会财富分配”不平等提供解决方案 。
					</p>
					<p>
						2019年1月10日，国家互联网信息办公室发布《区块链信息服务管理规定》 。2019年10月24日，在中央政治局第十八次集体学习时，习近平总书记强调，“把区块链作为核心技术自主创新的重要突破口”“加快推动区块链技术和产业创新发展”。“区块链”已走进大众视野，成为社会的关注焦点。2019年12月2日，该词入选《咬文嚼字》2019年十大流行语。
					</p>
					<p>
						2021年，国家高度重视区块链行业发展，各部委发布的区块链相关政策已超60项，区块链不仅被写入“十四五”规划纲要中，各部门更是积极探索区块链发展方向，全方位推动区块链技术赋能各领域发展，积极出台相关政策，强调各领域与区块链技术的结合，加快推动区块链技术和产业创新发展，区块链产业政策环境持续利好发展。
					</p>
				</div>
				<div class="name">
					类型
				</div>
				<div class="ii">
					<div class="tt">
						公有区块链
					</div>
					<p>
						公有区块链（Public Block Chains）是指：世界上任何个体或者团体都可以发送交易，且交易能够获得该区块链的有效确认，任何人都可以参与其共识过程。公有区块链是最早的区块链，也是应用最广泛的区块链，各大bitcoins系列的虚拟数字货币均基于公有区块链，世界上有且仅有一条该币种对应的区块链
					</p>
					<div class="tt">
						联合（行业）区块链
					</div>
					<p>
						行业区块链（Consortium Block Chains）：由某个群体内部指定多个预选的节点为记账人，每个块的生成由所有的预选节点共同决定（预选节点参与共识过程），其他接入节点可以参与交易，但不过问记账过程（本质上还是托管记账，只是变成分布式记账，预选节点的多少，如何决定每个块的记账者成为该区块链的主要风险点），其他任何人可以通过该区块链开放的API进行限定查询
					</p>
					<div class="tt">
						私有区块链
					</div>
					<p>
						私有区块链（Private Block Chains）：仅仅使用区块链的总账技术进行记账，可以是一个公司，也可以是个人，独享该区块链的写入权限，本链与其他的分布式存储方案没有太大区别。传统金融都是想实验尝试私有区块链，而公链的应用例如bitcoin已经工业化，私链的应用产品还在摸索当中
					</p>
				</div>
				<div class="name">
					特征
				</div>
				<div class="ii">
					<p>
						去中心化。区块链技术不依赖额外的第三方管理机构或硬件设施，没有中心管制，除了自成一体的区块链本身，通过分布式核算和存储，各个节点实现了信息自我验证、传递和管理。去中心化是区块链最突出最本质的特征
					</p>
					<p>
						去中心化。区块链技术不依赖额外的第三方管理机构或硬件设施，没有中心管制，除了自成一体的区块链本身，通过分布式核算和存储，各个节点实现了信息自我验证、传递和管理。去中心化是区块链最突出最本质的特征
					</p>
					<p>
						开放性。区块链技术基础是开源的，除了交易各方的私有信息被加密外，区块链的数据对所有人开放，任何人都可以通过公开的接口查询区块链数据和开发相关应用，因此整个系统信息高度透明
					</p>
					<p>
						独立性。基于协商一致的规范和协议(类似比特币采用的哈希算法等各种数学算法)，整个区块链系统不依赖其他第三方，所有节点能够在系统内自动安全地验证、交换数据，不需要任何人为的干预
					</p>
					<p>
						安全性。只要不能掌控全部数据节点的51%，就无法肆意操控修改网络数据，这使区块链本身变得相对安全，避免了主观人为的数据变更
					</p>
					<p>
						匿名性。除非有法律规范要求，单从技术上来讲，各区块节点的身份信息不需要公开或验证，信息传递可以匿名进行
					</p>
				</div>
			</div>
			<div class="content" v-if="type == 2">
				<div class="title">
					NFT
				</div>
				<div class="link" @click="goToUrl">
					出自：维基百科
				</div>
				<div style="margin:15px 0px;">
					<img style="width:100%;" src="/img/NFT.jpeg">
				</div>
				<div class="ii">
					数字艺术收藏品是一种储存在区块链（数位账本）上的数据单位，它可以代表艺术品等独一无二的数字资产。其是一种加密代币，但与比特币等加密货币不同，其不可互换。一个非同质化代币是透过上传一个文件，如艺术品，到非同质化代币拍卖市场，此举将创建一个记录在数字账本上的文件副本，以作为非同质化代币，可以透过加密货币来购买和转售。虽然创作者可以出售代表该作品的非同质化代币，但他们仍然可以保留作品的著作权，并创造更多的同一作品的非同质化代币。非同质化代币的买家并不能获得对作品的独家访问权，买家也不能获得对原始数字文件的独占性。将某一作品作为非同质化代币上传的人不必证明他们是原创艺术家，在许多争议案例中，在未经创作者许可的情况下，艺术品被盗用于非同质化代币。
				</div>
				<div class="name">
					用途
				</div>
				<div class="ii">
					<div class="tt">
						主要类型
					</div>
					<p>
						NFT是链接数字文件资产的数字代币，拥有NFT主要就是拥有证书以使用数字资产，只是买方通常不会被授予著作权。有时可以协议给买家证书作为个人、非商业的使用，有些证书也开放数字资产底本作为商业使用，毕竟都是特例。
					</p>
					<div class="tt">
						数字艺术
					</div>
					<p>
						美国策展人兼艺术史学者Tina Rivers Ryan专研数字艺术品，她说艺术博物馆普遍不认为NFT能创造“持续的文化联结”，[14]并拿NFT与互联网泡沫前的网络艺术热潮做比较。[15]尽管苏富比、佳士得这些拍卖行和世界各地的博物馆与画廊纷纷开始与Refik Anadol、Dangiuz和 Sarah Zucker 等数字艺术家合作，运用平台销售NFT，并透过虚拟画廊、生活中的银幕、显示器和电视来展示这些NFT作品，却还是没有统一的身份验证机制来防止盗卖行为或销售数字赝品。
					</p>
					<p>
						截至2022年5月，OpenSea总交易量（volume traded）最高的收藏品来自于：CryptoPunks、Ape Yacht Club 、Mutant Ape Yacht Club和 Art Blocks curated。CryptoPunks的作品多为派生艺术，而Art Blocks curated则更专注在用传统艺术形式来制作现代艺术家的收藏品，例如罗伯特﹒霍金（Robert Hodgin）的《虚构河流的古代路线》。
					</p>
					<p>
						最常应用NFT的当属数字艺术。[20]数字艺术相关NFT的拍卖相当高调所以受到大众广泛注意。2021年最昂贵的NFT作品是艺术家Pak的〈Merge〉，拍卖价格为9,180万美元，[21][22]而艺术家Beeple的 〈Everydays: the First 5000 Days〉 则以6,930万美元位居第二。
					</p>
					<p>
						EtherRocks或CryptoPunks的NFT藏品系列都属于派生艺术，用一系列简单图片组件的不同组合方式来创建各种图像
					</p>
					<p>
						2021年3月，区块链公司Injective Protocol以95,000美元从英国涂鸦艺术家Banksy 手中购买了一幅〈Morons （White）〉的丝网印刷原作，并拍摄了一段视频，片中有人在纽约某个公园用打火机燃烧了这幅作品，他们把视频铸造成NFT并出售。烧毁艺术品的人自称Burnt Banksy，说这样做是把实体艺术品转移到NFT空间的方式。
					</p>
					<p>
						2022年5月，Beeple与流行乐天后玛丹娜（Madonna）联手创作NFT《Mother of Creation》系列，共三项作品主要以视频加上文字或音乐元素来制作，是玛丹娜推出的首组NFT。
					</p>
					<div class="tt">
						区块炼游戏
					</div>
					<p>
						NFT可以代表游戏中的资产，诸如数字地皮。有评论员认为如果数字资产可以在未经游戏开发商许可的情况下于第三方市场上交易，数字资产才算是由用户控制。
					</p>
					<p>
						谜恋猫（CryptoKitties）是款早期成功的区块链在线游戏，[29]游戏中玩家可以收养和交易虚拟猫咪。游戏之NFT虚拟币值达到了1,250万美元投资额，有些小猫单只售价甚至超过10万美元。[30][31]谜恋猫运营成功后被加进ERC-721标准中，该标准创建于2018年1月，并于6月完成。[32][33]相似的NFT类型在线游戏还有于2018年3月推出的Axie Infinity。[
					</p>
					<p>
						2021年10月维尔福公司（Valve Corporation）流平台宣布，禁止应用程序使用区块链技术或NFT来进行等值兑换或交易游戏配件。
					</p>
					<p>
						2021年12月育碧娱乐公司（Ubisoft）公开发表NFT平台“Ubisoft Quartz”，允许人们使用加密货币购买珍稀的数字物品。[36]该公告引发了批评，YouTube上对这支发表视频的不喜欢率为96%，之后视频就下架了。[37]育碧的某些开发商也对此公告表示担忧。[38]2022年游戏开发者大会年度报告指出，70%受访的开发者表示并不想把NFT或加密货币集成到游戏中。 
					</p>
					<p>
						某些奢侈品品牌如杜嘉班纳（Dolce&Gabbana）替在线电视游戏铸造了外妆NFT。[40]2021年11月摩根士丹利发布了一份报告指出：到2030年这一类NFT应用的市场规模将达到数十亿美元。
					</p>
					<div class="tt">
						音乐
					</div>
					<p>
						据2021年2月报导，艺术家出售的作品和音乐NFT，为音乐产业创造了约2,500万美元的产值。[42]2021年2月28日美国DJ暨电子舞曲音乐家3LAU以总价1,170万美元售出33个NFT的合辑，以纪念他的专辑《Ultraviolet》出版三周年。[43]2021年3月3日里昂王族（ Kings of Leon）为了宣传专辑《When You See Yourself》也制作了NFT。[44]其他发行过NFT的音乐家还有：美国饶舌创作歌手利尔·庞普（Lil Pump）、[45]加拿大歌手及视觉艺术家格莱姆斯（Grimes）、[46]视觉艺术家Shepard Fairey与饶舌唱片制作人Mike Dean的共同作品、[47]美国饶舌歌手阿姆（Eminem）等。
					</p>
				</div>
				<div class="name">
					区块链规格
				</div>
				<div class="ii">
					<div class="tt">
						以太坊标准
					</div>
					<p>
						以太坊社区采用了许多标准，有助于在项目的不同实现中保持统一的兼容性（例如以太坊客户和虚拟钱包)，并确保智能合约和 dapps 仍保持兼容。ERC-721（全称：Ethereum Request for Comments 721）就是其中NFT相关的标准。
					</p>
					<p>
						随着NFT的普及和ERC721的应用，出现了ERC721合约的改良版。当中包括ERC721A。ERC721A 是 IERC721 的一种实现，在一次交易中铸造多个 NFT 可显著节省Gas Fee
					</p>
					<div class="tt">
						ERC-721
					</div>
					<p>
						所有 NFTs 都有一个uint256变量，名为tokenId，所以对于任何 ERC-721 合约，这对值contract address, tokenId 必须是全局唯一的。也就是说，dApp可以有一个“转换器”，该转换器使用tokenId输入和输出NFT对应的数字图像。
						
						ERC-721由 William Entriken、Dieter Shirley、Jacob Evans、Nastassia Sachs 在 2018 年 1 月提出，是一个在智能合约中实现代币 API 的非同质化代币标准。它提供了一些功能，例如将代币从一个帐户转移到另一个帐户，获取帐户的当前代币余额，获取代币的所有者，以及整个网络的可用代币总供应量。 除此之外，它还具有其他功能，例如批准帐户中一定数量的代币可以被第三方帐户转移。
					</p>
					<div class="tt">
						其他规格
					</div>
					<p>
						比特币现金支持NFTs。
					</p>
					<p>
						卡尔达诺区块链平台（Cardano）在 2021年3月份更新原创代币，可于无智能合约下创建NFT。
					</p>
					<p>
						Flow区块链（使用持有量证明共识模型）支持NFTs。谜恋猫于2021年3月宣布“不久的将来”将从以太坊转换到Flow区块链。
					</p>
					<p>
						Solana区块链支持NFTs。
					</p>
					<p>
						Tezos是一个基于持有量证明的区块炼网络，支持NFT艺术品的销售。
					</p>
				</div>
				<div class="name">
					发展历史
				</div>
				<div class="ii">
					<div class="tt">
						早期历史（2014年－2017年）
					</div>
					<p>
						2014年5月，Kevin McCoy and Anil Dash创造了第一个已知的NFT，其中包括McCoy的妻子Jennifer制作的一个视频剪辑。McCoy在Namecoin区块链上注册了这个视频，并以4美元的价格卖给了Dash，在纽约市新博物馆举行的七对七会议上进行了现场演示。McCoy和Dash称这项技术为“货币化图形”。一个不可替代的、可交易的区块链标记通过链上元数据（Namecoin启用）明确链接到一件艺术品上。这与其他区块链和平台上的多单元、可替换、缺少元数据的同质货币形成了鲜明对比。[55]
						2015年10月，第一个 NFT 项目 Etheria 在伦敦举行的DEVCON 1展示会上启动，这是以太坊的第一次开发者大会，距离以太坊区块链启动已经过去了三个月。Etheria 的457块可购买和可交易的六角形瓷砖中的大部分在超过五年的时间里都没有卖出去，直到2021年3月13日重新爆发对NFT的购买热潮。在24小时内，所有当前版本和以前版本的瓷砖，每个以1 ETH的价格（发布之初价值0.43美元）售出，总共售价140万美元。[56]
						NFT一词只是在ERC-721标准下才得以流行，ERC-721标准是2017年通过 Ethereum GitHub 首次提出的。当年启动了多个NFT项目，这个标准与几个NFT项目同期发布，其中包括Curio Cards、CryptoPunks（一个交易独特卡通人物的项目，由美国工作室 verla Labs 在 Ethereum 区块链上发布）和Rare Pepe交易卡片。
					</p>
					<div class="tt">
						进入大众视野（2017年－至今）
					</div>
					<p>
						2017年的在线游戏CryptoKitties通过销售可交易的猫咪NFT赚钱，它的成功引起了公众对NFT的关注。
					</p>
					<p>
						在2020年，NFT市场经历了快速增长，市值增长了三倍，达到2.5亿美元。在2021年的前3个月，超过2亿美元被用于NFT交易。
					</p>
					<p>
						在2021年的前几个月，在一系列引人注目的销售和艺术品拍卖之后，人们对NFT的兴趣持续增加。
					</p>
					<p>
						根据NFT数据网站NonFungible的统计，2021年9月NFT平均日销量约为15.3万件（当月单日销售量甚至曾达到22.4万件高峰），相较之下，2022年4月NFT平均日销量只剩3.6万件，衰退了76％；对比2021年9月与2022年4月的月销售总额，也下降了约22％。[59]华尔街日报的播客专访提及，NFT销售额下降使许多持有者账面上的数字资产价值远低于实际支付的价格，一方面固然是投资人担忧通货膨胀和美国联邦准备系统将改变货币政策，使得股票和债券都在下跌，NFT算是种投机性的赌注，当然也会下跌；另一方面，就算每个NFT都是独特的数字资产并具有价值，当它变得太受欢迎以至于供过于求，就目前的数据来看，已创造的NFT超过900万件而买家却不到200万人，一小群口袋有限的买家面对这样一个庞大的、每个NFT都是独一无二的数字资产市场，问题不言而喻。
					</p>
				</div>
			</div>
			<div class="content" v-if="type == 3">
				<div class="title">
					元宇宙
				</div>
				<div class="link" @click="goToUrl">
					出自：百度百科
				</div>
				<div style="margin:15px 0px;">
					<img style="width:100%;" src="/img/YFZ.jpeg">
				</div>
				<div class="ii">
					<p>
						元宇宙（Metaverse）是利用科技手段进行链接与创造的，与现实世界映射与交互的虚拟世界，具备新型社会体系的数字生活空间。
					</p>
					<p>
						元宇宙本质上是对现实世界的虚拟化、数字化过程，需要对内容生产、经济系统、用户体验以及实体世界内容等进行大量改造。但元宇宙的发展是循序渐进的，是在共享的基础设施、标准及协议的支撑下，由众多工具、平台不断融合、进化而最终成形。 [23]  它基于扩展现实技术提供沉浸式体验，基于数字孪生技术生成现实世界的镜像，基于区块链技术搭建经济体系，将虚拟世界与现实世界在经济系统、社交系统、身份系统上密切融合，并且允许每个用户进行内容生产和世界编辑。
					</p>
					<p>
						元宇宙一词诞生于1992年的科幻小说《雪崩》，小说描绘了一个庞大的虚拟现实世界，在这里，人们用数字化身来控制，并相互竞争以提高自己的地位，到现在看来，描述的还是超前的未来世界。 [1]  关于“元宇宙”，比较认可的思想源头是美国数学家和计算机专家弗诺·文奇教授，在其1981年出版的小说《真名实姓》中，创造性地构思了一个通过脑机接口进入并获得感官体验的虚拟世界。
					</p>
					<p>
						2021年12月，入选《柯林斯词典》2021年度热词 [21]  ；12月6日，入选“2021年度十大网络用语”。 [22]  12月8日，入选《咬文嚼字》“2021年度十大流行语”。 [30]  2022年1月，入选智库公布2021年度十大热词。
					</p>
				</div>
				<div class="name">
					名词定义
				</div>
				<div class="ii">
					<p>
						北京大学陈刚教授、董浩宇博士这样定义元宇宙：“元宇宙是利用科技手段进行链接与创造的，与现实世界映射与交互的虚拟世界，具备新型社会体系的数字生活空间。”
					</p>
					<p>
						清华大学新闻学院沈阳教授这样定义元宇宙：“元宇宙是整合多种新技术而产生的新型虚实相融的互联网应用和社会形态，它基于扩展现实技术提供沉浸式体验，以及数字孪生技术生成现实世界的镜像，通过区块链技术搭建经济体系，将虚拟世界与现实世界在经济系统、社交系统、身份系统上密切融合，并且允许每个用户进行内容生产和编辑。” [13]  “元宇宙仍是一个不断发展，演变的概念，不同参与者以自己的方式不断丰富着它的含义。”
					</p>
					<p>
						也有学者通过对元宇宙构思和概念的“考古”，可以从时空性、真实性、独立性、连接性四个方面去交叉定义元宇宙。
					</p>
					<p>
						从时空性来看，元宇宙是一个空间维度上虚拟而时间维度上真实的数字世界；从真实性来看，元宇宙中既有现实世界的数字化复制物，也有虚拟世界的创造物；从独立性来看，元宇宙是一个与外部真实世界既紧密相连，又高度独立的平行空间；从连接性来看，元宇宙是一个把网络、硬件终端和用户囊括进来的一个永续的、广覆盖的虚拟现实系统。
					</p>
					<p>
						准确地说，元宇宙不是一个新的概念，它更像是一个经典概念的重生，是在扩展现实（XR）、区块链、云计算、数字孪生等新技术下的概念具化。
					</p>
					<p>
						在原著中，元宇宙（Metaverse）是由Meta和Verse两个单词组成，Meta表示超越，Verse代表宇宙(universe)，合起来即为“超越宇宙”的概念：一个平行于现实世界运行的人造空间，是互联网的下一个阶段， 由AR、 VR、3D等技术支持的虚拟现实的网络世界。 
					</p>
				</div>
				<div class="name">
					发展历史
				</div>
				<div class="ii">
					<p>
						元宇宙始于1992年国外科幻作品《雪崩》里提到的“metaverse（元宇宙）”和“Avatar（化身）”这两个概念。人们在“Metaverse”里可以拥有自己的虚拟替身，这个虚拟的世界就叫做“元宇宙”。
					</p>
					<p>
						20世纪70年代到95年代出现了大量的开放性多人游戏，也就是说游戏本身的开放世界形成了元宇宙的早期基础。后来，2003年有一款游戏叫《Second Life》发布，它在理念上给我们部分解放了现实世界所面临的窘境，这句话怎么理解。就是我们在现实世界中最痛苦的一件事是不能快速调整自己的身份，而在虚拟世界当中，我们可以通过拥有自己的分身来实现，所以《Second Life》给了我们过一种新生活的可能性。
					</p>
					<p>
						2020年人类社会到达虚拟化的临界点，疫情加速了新技术的发展，加速了非接触式文化的形成。
					</p>
					<p>
						2021年是元宇宙元年。2021年初，Soul App在行业内首次提出构建“社交元宇宙”。2021年3月，被称为元宇宙第一股的罗布乐思（Roblox）正式在纽约证券交易所上市；5月，微软首席执行官萨蒂亚·纳德拉表示公司正在努力打造一个“企业元宇宙”；8月，海尔率先发布的制造行业的首个智造元宇宙平台，涵盖工业互联网、人工智能、增强现实、虚拟现实及区块链技术，实现智能制造物理和虚拟融合，融合“厂、店、家”跨场景的体验，实现了消费者体验的提升。 [51-53]  8月，英伟达宣布推出全球首个为元宇宙建立提供基础的模拟和协作平台；8月，字节跳动斥巨资收购VR创业公司Pico；10月28日，美国社交媒体巨头脸书（Facebook）宣布更名为“元”（Meta），来源于“元宇宙”（Metaverse）；11月，虚拟世界平台Decentraland公司发布消息，巴巴多斯将在元宇宙设立全球首个大使馆，暂定2022年1月启用 。11月，中国民营科技实业家协会元宇宙工作委员会揭牌
					</p>
					<p>
						2021年12月21日，百度发布的首个国产元宇宙产品“希壤”正式开放定向内测，用户凭邀请码可以进入希壤空间进行超前体验。
					</p>
					<p>
						2021年12月27日，百度Create AI开发者大会将发布元宇宙产品“希壤”，2021年的Create大会在“希壤APP”里举办，这是国内首次在元宇宙中举办的大会，可同时容纳10万人同屏互动。
					</p>
					<p>
						2022年1月，索尼（Sony）宣布了下一代虚拟现实头盔（PS VR2）的新细节，以及一款适配PS VR2的新游戏。
					</p>
					<p>
						2022年1月4日 高通技术公司在2022年国际消费电子展（CES）上宣布与微软合作，扩展并加速AR在消费级和企业级市场的应用。双方对元宇宙的发展充满信心，高通技术公司正与微软在多项计划中展开合作，共同推动生态系统发展，包括开发定制化AR芯片以打造新一代高能效、轻量化AR眼镜，从而提供丰富的沉浸式体验；并计划集成Microsoft Mesh应用和骁龙Spaces™ XR开发者平台等软件。
					</p>
					<p>
						2022年1月，新鲜热词“元宇宙”屡登委员提案！
					</p>
					<p>
						2022年2月，中国科技巨头向“元宇宙”进发。
					</p>
					<p>
						2022年2月14日，香港海洋公园宣布：香港海洋公园、伙拍The Sandbox合作布局元宇宙。
					</p>
					<p>
						2022年4月25日晚间消息，据报道，Facebook母公司Meta宣布，其第一家“元宇宙”实体店将于5月开业，消费者可以在那里试用和购买虚拟现实（VR）头显和其他设备。
					</p>
					<p>
						2022年4月26日，在渝举行的中国元宇宙产业发展高峰论坛上，重庆市元宇宙先导试验区正式揭牌。
					</p>
					<p>
						2022年5月9日，“元宇宙首尔市政厅”向公众开放。
					</p>
				</div>
				<div class="name">
					理念特点
				</div>
				<div class="ii">
					<div class="tt">
						三大特征
					</div>
					<p>
						与现实世界平行、反作用于现实世界、多种高技术综合，是未来元宇宙的三大特征
					</p>
					<div class="tt">
						特征属性
					</div>
					<p>
						Roblox给出的元宇宙包含八大要素：身份、朋友、沉浸感、低延迟、多元化、随时随地、经济系统和文明。要素众多，每个要素背后，还有一连串的解释。总之，一句话说不清楚，这也恰恰说明这一概念的模糊性。
					</p>
					<p>
						清华大学新闻学院沈阳教授指出，一方面，现实中缺什么，虚拟世界中就需要补什么；另一方面，人们在虚拟世界里面做的事情，对于真实的世界有没有反哺的作用。然而从人类发展历史看，虚实之间的平衡将会变得越来越困难。
					</p>
					<p>
						在元宇宙特征与属性的START图谱中，北京大学陈刚教授与董浩宇博士梳理并系统界定了元宇宙的五大特征与属性，即：社会与空间属性(Social & Space)、科技赋能的超越延伸（Technology Tension）、人、机与人工智能共创（ArtifIcal, Machine & AI）、真实感与现实映射性（Reality & Reflection）、交易与流通（Trade & Transaction）。
					</p>
					<div class="tt">
						实现路径
					</div>
					<p>
						清华大学新闻学院沈阳教授认为元宇宙的实现路径如下：
					</p>
					<p>
						第一，沉浸和叠加。沉浸式路径的代表是VR技术，比如佩戴VR设备，可以让人进入一种“万物皆备于我”的沉浸式专属场景，这种场景既是沉浸的也是内卷的。叠加式路径的代表是AR技术，它是在现有条件上叠加和外拓，比如给普通机器人加入皮囊皮相、注入灵魂情感，令其成为仿真机器人。
					</p>
					<p>
						第二，激进和渐进。通往元宇宙的路径，一直有激进和渐进两种方式。比如Rolox就是激进路径的代表，从一开始就不提供游戏，只提供开发平台和社区，以创作激励机制吸引用户，实现完全由用户打造的去中心化世界。这意味着任何人都可以进入这个空间进行编辑，做剧本或设置游戏关卡等。
					</p>
					<p>
						第三，开放和封闭。元宇宙的路径还存在开放和封闭两种关系。这种关系在手机市场上体现较为明显，比如苹果系统就是一个封闭的系统，软硬件都是封闭的，我把这种逻辑总结为“我即宇宙”。
					</p>
					<div class="tt">
						核心技术
					</div>
					<p>
						清华大学新闻学院沈阳教授表示，“元宇宙本身不是一种技术，而是一个理念和概念，它需要整合不同的新技术，如5G、6G、人工智能、大数据等，强调虚实相融。”元宇宙主要有以下几项核心技术：
					</p>
					<p>
						一是扩展现实技术，包括VR和AR。扩展现实技术可以提供沉浸式的体验，可以解决手机解决不了的问题。
					</p>
					<p>
						二是数字孪生，能够把现实世界镜像到虚拟世界里面去。这也意味着在元宇宙里面，我们可以看到很多自己的虚拟分身。
					</p>
					<p>
						三是用区块链来搭建经济体系。随着元宇宙进一步发展，对整个现实社会的模拟程度加强，我们在元宇宙当中可能不仅仅是在花钱，而且有可能赚钱，这样在虚拟世界里同样形成了一套经济体系。
					</p>
					<p>
						在元宇宙时代，实现眼、耳、鼻、舌、身体、大脑六类需求（视觉、听觉、嗅觉、味觉、触觉、意识）有不同的技术支撑（4I），如网线和电脑支持了视觉和听觉需求，但这种连接还处在初级阶段。随着互联网的进一步发展，连接不仅满足需求，而且通过供给刺激需求、创造需求。如通过大数据精准“猜你喜欢”，直接把产品推给用户，实现“概率购买”的赌注。
					</p>
					<p>
						作为一种多项数字技术的综合集成应用，元宇宙场景从概念到真正落地需要实现两个技术突破：第一个是XR、数字孪生、区块链、人工智能等单项技术的突破，从不同维度实现立体视觉、深度沉浸、虚拟分身等元宇宙应用的基础功能；第二个突破是多项数字技术的综合应用突破，通过多技术的叠加兼容、交互融合，凝聚形成技术合力推动元宇宙稳定有序发展。
					</p>
					<div class="tt">
						布局模式
					</div>
					<p>
						清华大学新闻学院沈阳教授表示，元宇宙涉及到非常多的技术，包括人工智能、数字孪生、区块链、云计算、拓展现实、机器人、脑机接口、5G等，元宇宙的生态版图中有底层技术支撑、前端设备平台和场景内容入口。元宇宙有三个属性，一是包括时间和空间的时空性；二是包括虚拟人、自然人、机器人的人机性；三是基于区块链所产生的经济增值性。
					</p>
					<p>
						元宇宙在不同产业领域当中，发展速度是不一样的，如果某一个产业领域和元宇宙的三个属性有密切结合，它发展会更快，这包括游戏、展览、教育、设计规划、医疗、工业制造、政府公共服务等。未来我们所有的行业都需要在有空间性、人机性、经济增值性的元宇宙当中重新进入赛道。
					</p>
					<p>
						目前市场上的元宇宙公司有四套叙事逻辑，分为虚实融合、去中心化交易、自由创造、社交协作。
					</p>
					<p>
						从目前的情况来看，各大科技企业主要还是依托其既有优势来布局元宇宙领域，主要可以分为三种模式：
					</p>
					<p>
						第一种是聚焦核心元器件和基础性平台领域，加快布局元宇宙硬件入口和操作系统，以英伟达、Meta、微软等国际数字科技巨头为主，字节跳动等国内企业也在加快推进元宇宙相关硬件的研发。
					</p>
					<p>
						第二种是聚焦商业模式与内容场景，探索元宇宙相关应用场景落地，以国内数字科技巨头为主，如腾讯表示将在游戏、社交等领域加快对于元宇宙的研究开发。
					</p>
					<p>
						第三种是政府推动企业入局模式，以韩国企业为主。韩国是全球推进元宇宙产业发展最为积极的国家之一，其首都首尔在今年11月宣布成为首个加入元宇宙的城市政府；同时，韩国元宇宙产业的发展主要是相关政府部门牵头，引导和推动三星、现代汽车、LG等企业组成“元宇宙联盟”，形成企业在元宇宙领域的发展合力，以此推动实现更大范围的虚拟现实连接，并建立韩国国家级元宇宙发展平台。
					</p>
					<p>
						需要注意的是，国内外元宇宙领域的争夺战已经如火如荼，元宇宙产业的全球不均衡发展态势正在逐步形成，在此情景下，我国企业应强化元宇宙产业发展的科技硬实力。
					</p>
					<div class="tt">
						引发变化
					</div>
					<p>
						中国社会科学院数量经济与技术经济研究所信息化与网络经济研究室副主任、中国社会科学院信息化研究中心秘书长左鹏飞认为，元宇宙将给我们的生活和社会经济发展带来五个方面的巨变：
					</p>
					<p>
						（1）从技术创新和协作方式上，进一步提高社会生产效率；
					</p>
					<p>
						（2）催生出一系列新技术新业态新模式，促进传统产业变革；
					</p>
					<p>
						（3）推动文创产业跨界衍生，极大刺激信息消费；
					</p>
					<p>
						（4）重构工作生活方式，大量工作和生活将在虚拟世界发生；
					</p>
					<p>
						（5）推动智慧城市建设，创新社会治理模式。
					</p>
				</div>
				<div class="name">
					发展前景
				</div>
				<div class="ii">
					<p>
						中国社会科学院数量经济与技术经济研究所副研究员左鹏飞认为：
					</p>
					<p>
						从政府和企业的行为可以看出，虽然目前外部对于元宇宙概念和属性的看法仍在不断变化，但是对于元宇宙未来的良好前景已基本形成共识。展望未来，元宇宙的3种前景在当下已经较为显著。
					</p>
					<p>
						首先，从市场规模的前景来看，目前已有多个国际知名咨询机构公开表示看好元宇宙的未来市场规模。如普华永道预计，2030年元宇宙市场规模将达到1.5万亿美元，彭博行业则估计届时元宇宙市场规模可以达到2.5万亿美元；摩根士丹利预计，未来元宇宙潜在市场空间将超8万亿美元。不仅如此，元宇宙在其发展过程中，还将拉动壮大其他领域的市场规模。
					</p>
					<p>
						其次，从产业创新的前景来看，元宇宙带来的产业创新前景包括了两方面：元宇宙将打破我们所习惯的现实世界物理规则，以全新方式激发产业技术创新；此外，元宇宙将与不同产业深度融合，以新模式、新业态带动相关产业跃迁升级。
					</p>
					<p>
						最后，从应用范围的前景来看，当前元宇宙的应用主要表现在游戏、娱乐等领域，其他领域应用相对较少。未来，伴随元宇宙技术和产业成熟度的持续提高，其应用范围将逐步扩大，并不断深入。如元宇宙或将在社会治理、公共服务等领域具有巨大的应用前景。
					</p>
					<p>
						2022年，data.ai（原 App Annie）发布《2022年移动市场报告》指出，社交应用的下一个重大变革可能会是虚拟人物和元宇宙，分析师预计元宇宙游戏在2022年的应用商店年用户支出将超过31亿美元。
					</p>
				</div>
			</div>
		</div>
	</div>
	<Footer></Footer>
</template>

<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import { VideoPlay,Warning,ArrowDown,Brush } from '@element-plus/icons-vue'
	export default {
		components: { Header,Footer },
		data(){
			return{
				type:1,
			}
		},
		created(){
			let type = this.$route.params.type;
			this.type = type;
		},
		methods: {
			goToUrl(){
				let type = this.type;
				if(type == 1){
					window.open('https://baike.baidu.com/item/%E5%8C%BA%E5%9D%97%E9%93%BE/13465666?fr=aladdin')
				}
				if(type == 2){
					window.open('https://zh.wikipedia.org/wiki/NFT')
				}
				if(type == 3){
					window.open('https://baike.baidu.com/item/%E5%85%83%E5%AE%87%E5%AE%99/58292530')
				}
			}
		}
	}
</script>

<style scoped>
	.Material{
		background:#F3FBFE;
		padding-top:30px;
	}
	.MaterialBox{
		width:1280px;
		margin:0px auto;
		background:#fff;
		border-radius:10px;
		border-radius: 10px;
		border: 1px solid #e5e8eb;
		padding:30px;
	}
	.content{
		width:760px;
		margin:0px auto;
	}
	.content .title{
		font-size:30px;
		font-weight:bold;
		text-align: center;
	}
	.content .link{
		color: #999;
		text-decoration: none;
		font-size: 14px;
	}
	.content .ii{
		font-size: 16px;
		line-height: 2;
		margin-top:10px;
	}
	.content .ii p{
		text-indent: 2em;
	}
	.content .tt{
		font-size:20px;
		font-weight:bold;
	}
	.content .name{
		font-size:24px;
		font-weight:bold;
		margin-top:25px;
	}
</style>
